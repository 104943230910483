<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键字..."
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

                <el-table :data="tableData" stripe height="calc(100% - 80px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="shareMoney" label="分红金额（元）" width="150" align="center">
                    </el-table-column>
                    <el-table-column label="普通会员" align="center">
                        <el-table-column prop="commonTotal" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="commonMoney" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="VIP1" align="center">
                        <el-table-column prop="vip1Total" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="vip1Money" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="VIP2" align="center">
                        <el-table-column prop="vip2Total" label="人数" align="center">
                        </el-table-column>
                        <el-table-column prop="vip2money" label="共计金额（元）" align="center">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="shareType" label="分红方式" align="center">
                        <template slot-scope="scope">
                            <p v-if="scope.row.shareType==0">团对平均分</p>
                            <p v-if="scope.row.shareType==2">团长独占</p>
                            <p v-if="scope.row.shareType==1">按比例分</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orgOperationUserName" label="操作人" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.opertionType==1">【机构代分】<p>{{ scope.row.orgOperationUserName}}</p>
                            </div>
                            <div v-if="scope.row.opertionType==0">【团长分】<p>{{ scope.row.teamUser.userName}}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orgOperationAccount" label="操作账户" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.opertionType==1">{{ scope.row.orgOperationAccount}}</span>
                            <span v-if="scope.row.opertionType==0">{{ scope.row.teamUser.phone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="分红时间" align="center" width="150px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.addTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <span class="primary optionBtn" @click="toDetail(scope.row)">分红详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="分红明细" :visible.sync="scaleDialog2" class="dialog">
            <el-table :data="tableData2" stripe height="calc(100% - 187px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column prop="user.userName" label="会员名称" width="150" align="center">
                </el-table-column>
                <el-table-column prop="user.phone" label="会员账号" align="center">
                </el-table-column>
                <el-table-column prop="money" label="分红金额" align="center"> </el-table-column>
                <el-table-column prop="addTime" label="分红时间" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.addTime | timefilters }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="ChangePage2" :page-size="PageSize2" :current-page="PageIndex2"
                :total="DataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
        </el-dialog>
        <!--    开票申请    -->
        <el-dialog title="设置分红比例" :visible.sync="scaleDialog" class="dialog">
            <el-form :model="percentInfo" ref="percentInfo" :rules="rules">
                <el-form-item label="普通会员分红比例" :label-width="formLabelWidth" prop="commonPercent">
                    <el-input type="number" v-model.number="percentInfo.commonPercent" size="small"></el-input>
                    <span>%</span>
                </el-form-item>
                <el-form-item label="VIP1分红比例" :label-width="formLabelWidth" prop="vip1Percent">
                    <el-input type="number" v-model.number="percentInfo.vip1Percent" size="small"></el-input>
                    <span>%</span>
                </el-form-item>
                <el-form-item label="VIP2分红比例" :label-width="formLabelWidth" prop="vip2Percent">
                    <el-input type="number" v-model.number="percentInfo.vip2Percent" size="small"></el-input>
                    <span>%</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="scaleDialog = false">取 消</el-button>
                <el-button type="primary" @click="scaleSave('percentInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Distributor } from "../../../components/HospitalDomain/Distributor";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            tableData2: [],
            searchForm: {
                type: '0',
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            purpose: ['upgradeMember', 'renewalMember', 'buyMember'],
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            PageIndex2: 1,
            PageTotal2: 1,
            PageSize2: 15,
            DataTotal2: 0,
            commanderId:this.$route.query.id,
            formLabelWidth: '150px',
            scaleForm: {
                memberScale: 0,
                VIPMember1Scale: 0,
                VIPMember2Scale: 0,
            },
            percentInfo: {
                commonPercent: 0,
                vip1Percent: 0,
                vip2Percent: 0,
            },
            ShareData: {},
            ShareDetail: {},
            scaleDialog: false,
            scaleDialog2: false,
            recordId: '',
            rules: {
                commonPercent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入普通会员分红比例', trigger: 'blur' },
                ],
                vip1Percent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入VIP1会员分红比例', trigger: 'blur' },
                ],
                vip2Percent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入VIP2会员分红比例', trigger: 'blur' },
                ]
            }
        }
    },
    mounted() {
        this.getList();
        // this.getShareInfo();
    },
    methods: {
        toDetail(item) {
            this.PageIndex2 = 1;
            this.recordId = item.id
            this.getPublicShareUserDetail()
        },
        getPublicShareUserDetail() {
            var _this = this
            _this.DistributorDomain.PublicShareUserDetail(_this.recordId, 1, _this.PageIndex2,
                function (data) {
                    _this.scaleDialog2 = true
                    _this.tableData2 = data.data.results;
                    _this.PageIndex2 = data.data.pageIndex;
                    _this.PageSize2 = data.data.pageSize;
                    _this.PageTotal2 = data.data.pageCount;
                    _this.DataTotal2 = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        showSetScale() {
            this.scaleDialog = true
        },
        search(event) {
            this.PageIndex = 1;
            this.getList()
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        ChangePage2(pageIndex) {
            this.PageIndex2 = pageIndex;
            this.getPublicShareUserDetail();
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.AllTeamShareRecore(_this.commanderId,item.keyWord, item.startTime, item.endTime, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        toShare() {
            var _this = this;
            var num1 = _this.percentInfo.commonPercent * 1 + _this.percentInfo.vip1Percent * 1 + _this.percentInfo.vip2Percent * 1
            if (num1 > 99) {
                _this.$message({
                    type: 'error',
                    message: '分红比例超出最大值'
                });
                return
            } else if (num1 == 0) {
                _this.$message({
                    type: 'error',
                    message: '请先设置分红比例'
                });
                return
            }
            _this.$confirm('确定要分红吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.DistributorDomain.DistributePublicShare(function (data) {
                    _this.PageIndex = 1;
                    _this.getList();
                }, function (err) {
                    _this.getShareInfo();
                    _this.PageIndex = 1;
                    _this.getList();
                    console.log(err);
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        getShareInfo() {
            var _this = this;
            _this.ShareData = [];
            _this.DistributorDomain.GetPublicShareInfo(function (data) {
                _this.ShareData = data.data;
                _this.percentInfo.commonPercent = _this.ShareData.commonPercent
                _this.percentInfo.vip1Percent = _this.ShareData.vip1Percent
                _this.percentInfo.vip2Percent = _this.ShareData.vip2Percent
            }, function (err) { console.log(err); });
        },
        scaleSave(formName) {
            var _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    var num1 = _this.percentInfo.commonPercent * 1 + _this.percentInfo.vip1Percent * 1 + _this.percentInfo.vip2Percent * 1
                    console.log(num1)
                    if (num1 > 99) {
                        _this.$message({
                            type: 'error',
                            message: '分红比例超出最大值'
                        });
                        return
                    }
                    _this.DistributorDomain.SubmitShare(_this.percentInfo, function (data) {
                        _this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                        _this.scaleDialog = false;
                        _this.getShareInfo();
                    }, function (err) { console.log(err); });
                }
            })

        },
    }
}
</script>

<style scoped>
.el-input {
    width: 95%;
}

.iq-search-bar /deep/.el-form-item {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-descriptions__header {
    margin-bottom: 10px;
}
</style>
